import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{
      keepAlive:true,
      showTab:true,
      showBottom:true
    },
    component: () => import(/* webpackChunkName: "Home" */ `@/views/home/index.vue`)
  },
  {
    path: '/test',
    name: 'Test',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Test" */ `@/views/home/test.vue`)
  },
  {
    path: '/new_more',
    name: 'new_more',
    meta:{
      keepAlive:true,
      useKeep:true,
      noShow:true
    },
    component: () => import(/* webpackChunkName: "new_more" */ `@/views/home/new_more.vue`)
  },
  {
    path: '/nftshop',
    name: 'Nftshop',
    component: () => import(/* webpackChunkName: "Nftshop" */ `@/views/nft/shop.vue`)
  },
  {
    path: '/included',
    name: 'Included',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Included" */ `@/views/nft/included.vue`)
  },
  {
    path: '/rankinglist',
    name: 'Rankinglist',
    meta:{
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "Rankinglist" */ `@/views/nft/rankinglist.vue`)
  },
  {
    path: '/collectionshop',
    name: 'Collectionshop',
    component: () => import(/* webpackChunkName: "Collectionshop" */ `@/views/nft/collectionshop.vue`)
  },
  {
    path: '/casting',
    name: 'Casting',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Casting" */ `@/views/nft/casting.vue`)
  },
  {
    path: '/nftdetail',
    name: 'Nftdetail',
    meta:{
      showBottom:true
    },
    component: () => import(/* webpackChunkName: "Nftdetail" */ `@/views/nft/detail.vue`)
  },
  {
    path: '/upinfo',
    name: 'Upinfo',
    meta:{
      showBottom:true
    },
    component: () => import(/* webpackChunkName: "Upinfo" */ `@/views/nft/upinfo.vue`)
  },
  {
    path: '/createcollection',
    name: 'Createcollection',
    component: () => import(/* webpackChunkName: "Createcollection" */ `@/views/nft/createcollection.vue`)
  },
  {
    path: '/exchange',
    name: 'Exchange',
    meta:{
      showBottom:true,
      noShow:true,
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "Exchange" */ `@/views/operate/exchange.vue`)
  },
  {
    path: '/choosenft',
    name: 'Choosenft',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Choosenft" */ `@/views/operate/choosenft.vue`)
  },
  {
    path: '/synthesis',
    name: 'Synthesis',
    meta:{
      showBottom:true
    },
    component: () => import(/* webpackChunkName: "Synthesis" */ `@/views/operate/synthesis.vue`)
  },
  {
    path: '/pledge',
    name: 'Pledge',
    meta:{
      showBottom:true,
    },
    component: () => import(/* webpackChunkName: "Pledge" */ `@/views/operate/pledge.vue`)
  },
  {
    path: '/pledgeitem/:type',
    name: 'Pledgeitem',
    component: () => import(/* webpackChunkName: "Pledgeitem" */ `@/views/operate/pledgeitem.vue`)
  },
  {
    path: '/pledgedetail/:type',
    name: 'Pledgedetail',
    meta:{
      showBottom:true
    },
    component: () => import(/* webpackChunkName: "Pledgedetail" */ `@/views/operate/pledgedetail.vue`)
  },
  {
    path: '/crosschain',
    name: 'Crosschain',
    meta:{
      showBottom:true
    },
    component: () => import(/* webpackChunkName: "Crosschain" */ `@/views/operate/crosschain.vue`)
  },
  {
    path: '/globalnode',
    name: 'Globalnode',
    component: () => import(/* webpackChunkName: "Globalnode" */ `@/views/operate/globalnode.vue`)
  },
  {
    path: '/introduce',
    name: 'Introduce',
    meta:{
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "Introduce" */ `@/views/operate/introduce.vue`)
  },
  {
    path: '/exchangelist',
    name: 'Exchangelist',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Exchangelist" */ `@/views/record/exchangelist.vue`)
  },
  {
    path: '/syntheticlist',
    name: 'Syntheticlist',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Syntheticlist" */ `@/views/record/syntheticlist.vue`)
  },
  {
    path: '/redemptionlist',
    name: 'Redemptionlist',
    component: () => import(/* webpackChunkName: "Redemptionlist" */ `@/views/record/redemptionlist.vue`)
  },
  {
    path: '/receivelist',
    name: 'Receivelist',
    component: () => import(/* webpackChunkName: "Receivelist" */ `@/views/record/receivelist.vue`)
  },
  {
    path: '/pledgelist',
    name: 'Pledgelist',
    component: () => import(/* webpackChunkName: "Pledgelist" */ `@/views/record/pledgelist.vue`)
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "Invite" */ `@/views/record/invite.vue`)
  },
  {
    path: '/crosschainlist',
    name: 'Crosschainlist',
    component: () => import(/* webpackChunkName: "Crosschainlist" */ `@/views/record/crosschainlist.vue`)
  },
  {
    path: '/nodedetail',
    name: 'Nodedetail',
    meta:{
      showBottom:true
    },
    component: () => import(/* webpackChunkName: "Nodedetail" */ `@/views/record/nodedetail.vue`)
  },
  {
    path: '/castinglist',
    name: 'Castinglist',
    component: () => import(/* webpackChunkName: "Castinglist" */ `@/views/record/castinglist.vue`)
  },
  {
    path: '/buylist',
    name: 'Buylist',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Buylist" */ `@/views/record/buylist.vue`)
  },
  {
    path: '/nodepush1',
    name: 'Nodepush1',
    component: () => import(/* webpackChunkName: "Nodepush1" */ `@/views/record/nodepush1.vue`)
  },
  {
    path: '/nodepush2',
    name: 'Nodepush2',
    component: () => import(/* webpackChunkName: "Nodepush2" */ `@/views/record/nodepush2.vue`)
  },
  {
    path: '/nodepush3',
    name: 'Nodepush3',
    component: () => import(/* webpackChunkName: "Nodepush2" */ `@/views/record/nodepush3.vue`)
  },
  {
    path: '/my',
    name: 'My',
    meta:{
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "My" */ `@/views/my/index.vue`)
  },
  {
    path: '/myinfo',
    name: 'Myinfo',
    component: () => import(/* webpackChunkName: "Myinfo" */ `@/views/my/info.vue`)
  },
  {
    path: '/minelists',
    name: 'Minelists',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Minelists" */ `@/views/my/minelists.vue`)
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import(/* webpackChunkName: "Notice" */ `@/views/show/notice.vue`)
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import(/* webpackChunkName: "Question" */ `@/views/show/question.vue`)
  },
  {
    path: '/lpPlege',
    name: 'LpPlege',
    meta:{
      showBottom:true,
      keepAlive:true
    },
    component: () => import(/* webpackChunkName: "LpPlege" */ `@/views/lp/index.vue`)
  },
  {
    path: '/lpzylist',
    name: 'Lpzylist',
    component: () => import(/* webpackChunkName: "Lpzylist" */ `@/views/lp/lpzylist.vue`)
  },
  {
    path: '/lpsflist',
    name: 'Lpsflist',
    component: () => import(/* webpackChunkName: "Lpsflist" */ `@/views/lp/lpsflist.vue`)
  },
  {
    path: '/lpfxlist',
    name: 'Lpfxlist',
    component: () => import(/* webpackChunkName: "Lpfxlist" */ `@/views/lp/lpfxlist.vue`)
  },
  {
    path: '/lpnode',
    name: 'Lpnode',
    component: () => import(/* webpackChunkName: "Lpnode" */ `@/views/lp/lpnode.vue`)
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Leaderboard" */ `@/views/new/leaderboard.vue`)
  },
  {
    path: '/hashrate',
    name: 'Hashrate',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Hashrate" */ `@/views/new/hashrate.vue`)
  },
  {
    path: '/search',
    name: 'Search',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Search" */ `@/views/new/search.vue`)
  },
  {
    path: '/set',
    name: 'Set',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Set" */ `@/views/new/set.vue`)
  },
  {
    path: '/sort',
    name: 'Sort',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Sort" */ `@/views/new/sort.vue`)
  },
  {
    path: '/mints',
    name: 'Mints',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Mints" */ `@/views/new/mints.vue`)
  },
  {
    path: '/apply',
    name: 'Apply',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Apply" */ `@/views/new/apply.vue`)
  },
  {
    path: '/newdetail',
    name: 'Newdetail',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "newdetail" */ `@/views/new/detail.vue`)
  },
  {
    path: '/jslist',
    name: 'Jslist',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Jslist" */ `@/views/new/jslist.vue`)
  },
  {
    path: '/bhlist',
    name: 'Bhlist',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Bhlist" */ `@/views/new/bhlist.vue`)
  },
  {
    path: '/applylist',
    name: 'Applylist',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Applylist" */ `@/views/new/applylist.vue`)
  },
  {
    path: '/copyrightlist',
    name: 'Copyrightlist',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Copyrightlist" */ `@/views/record/copyrightlist.vue`)
  },
  {
    path: '/mpquery',
    name: 'Mpquery',
    meta:{
      noShow:true
    },
    component: () => import(/* webpackChunkName: "Mpquery" */ `@/views/operate/mpquery.vue`)
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition
    } else{
      return { x: 0, y: 0}
    }
  },
})

router.beforeEach((to,from,next)=>{
  // document.title = to.meta.title ? to.meta.title : 'TrueShop'
  document.title = 'TrueShop'
  next()
})

export default router
