export default {
  text: {
    t1: 'Privacy Policy',
    t2: 'Contact us',
    t3: 'Service Agreement',
    t4: 'The jump link does not exist',
    t5: 'Activity',
    t6: 'Casting',
    t7: 'transfer into',
    t8: 'Exchange',
    t9: 'Synthetic',
    t10: 'Pledge',
    t11: 'Cross-chain exchange',
    t12: 'Global node',
    t13: 'Invitation',
    t14: 'My',
    t15: 'Logout',
    t16: 'Connect wallet',
    t17: 'Copy successfully',
    t18: 'Copy failed',
    t19: 'Public area',
    t20: 'Create a collection',
    t21: 'Select the hottest NFT',
    t22: 'NFT Square',
    t23: 'Get the most favorite collection',
    t24: 'Volume',
    t25: 'holder',
    t26: 'Favorite Ranking',
    t27: 'The most popular collection',
    t28: 'About True Shop',
    t29: 'Complete User Guide',
    t30: 'FAQ',
    t31: 'default nickname',
    t32: 'Introduction',
    t33: 'Introduction has not been filled in yet',
    t34: 'fans',
    t35: 'Follow',
    t36: 'on the shelf',
    t37: 'My NFT',
    t38: 'Search for the NFT contract address you are looking for',
    t39: 'No more',

    t40: 'My Collection',
    t41: 'Search the name of the collection you are looking for',
    t42: 'Favorite NFT',
    t43: 'Search for the NFT name you are looking for',
    t44: 'price',
    t45: 'The Collection of Favorites',
    t46: 'I cast',
    t47: 'I listed it',
    t48: 'I follow',
    t49: 'Search for the username you are looking for',

    t50: 'No nickname yet',
    t51: 'Change collection',
    t52: 'Cancel',
    t53: 'collection',
    t54: 'Please select a collection',
    t55: 'move',
    t56: 'Profile Details',
    t57: 'Profile picture',
    t58: 'Profile Banner',
    t59: 'username',

    t60: 'Enter your username',
    t61: 'Introduction',
    t62: 'Please enter your brief introduction',
    t63: 'email address',
    t64: 'Enter your email',
    t65: 'Wallet address',
    t66: 'One key copy',
    t67: 'Committing...',
    t68: 'OK',
    t69: 'Please upload a profile picture',

    t70: 'Please upload a profile banner',
    t71: 'Please enter your username',
    t72: 'Please enter your brief introduction',
    t73: 'Please enter your email',
    t74: 'Uploading...',
    t75: 'Upload successful',
    t76: 'Upload failed',
    t77: 'View details',
    t78: 'Favorites',
    t79: 'Collection Collection',

    t80: 'Collection description',
    t81: 'Search for your favorite NFT',
    t82: 'Edit collection',
    t83: 'Create collection',
    t84: 'logo image',
    t85: 'This picture is recommended',
    t86: 'Featured image',
    t87: 'size',
    t88: 'background image',
    t89: 'collection name',

    t90: 'Please enter the name of the collection',
    t91: 'Introduction to the collection',
    t92: 'Please enter the collection introduction',
    t93: 'create',
    t94: 'Delete collection',
    t95: 'Please upload a logo image',
    t96: 'Please upload a featured image',
    t97: 'Please upload a background image',
    t98: 'Please enter the name of the collection',
    t99: 'Please enter the collection introduction',

    t100: 'Removed',
    t101: 'Buy now',
    t102: 'Information',
    t103: 'quantity',
    t104: 'Contract address',
    t105: 'Token ID',
    t106: 'Token Standard',
    t107: 'Blockchain',
    t108: 'Creator name',
    t109: 'Creator Fee',

    t110: 'Pledge Description',
    t111: 'Price History',
    t112: 'Please select a date',
    t113: 'Project activity',
    t114: 'Pathway',
    t115: 'price',
    t116: 'holder',
    t117: 'Winner',
    t118: 'time',
    t119: 'Remove selection',

    t120: 'Purchase option',
    t121: 'Select the number of NFTs you delist',
    t122: 'Select the number of NFTs you purchased',
    t123: 'buy',
    t124: 'Total',
    t125: 'open can',
    t126: 'Favorite ranking',
    t127: 'quantity',
    t128: 'Please enter the quantity',
    t129: 'Please enter a price',

    t130: 'Transaction fee (excluding listing)',
    t131: 'Cross-chain exchange',
    t132: 'USDT cross-chain exchange',
    t133: 'Inventory',
    t134: 'The exchange ratio is',
    t135: 'Exchange',
    t136: 'Exchange pool',
    t137: 'Exchange of OMT tokens for NFT fragments',
    t138: 'fragment',
    t139: 'de-synthesize',

    t140: 'Getting scale...',
    t141: 'dan',
    t142: 'Ordinary user',
    t143: 'Earl One Star',
    t144: 'Earl Two Stars',
    t145: 'Earl Samsung',
    t146: 'Marquis One Star',
    t147: 'Marquis Two Stars',
    t148: 'Marquis Samsung',
    t149: 'Duke One Star',
    t150: 'Duke Two Stars',
    t151: 'Duke Samsung',
    t152: 'Personal pledge is required ',
    t153: ' open',
    t154: 'Direct push pledge ',
    t155: 'Interim pledge ',
    t156: ' Can be upgraded',
    t157: 'Node computing power',
    t158: 'My node code',
    t159: 'One key copy',
    t160: 'Enter node code',
    t161: 'Please enter the node code',
    t162: 'About global nodes',
    t163: 'Transfer to your NFT digital collection',
    t164: 'Please select a token standard',
    t165: 'Please enter your contract address',
    t166: 'query',
    t167: 'Select a collection',
    t168: 'Please select a collection',
    t169: 'Add',

    t170: 'No nft found',
    t171: 'True NFT pledge mining income',
    t172: 'True NFT for pledge and redemption',
    t173: 'All pledge',
    t174: 'Batch pledge',
    t175: 'Full redemption',
    t176: 'Redemption',
    t177: 'Batch redemption',
    t178: 'Accumulated pledge income',
    t179: 'Pledged NFT',

    t180: 'Stakeable NFT',
    t181: 'Redeemable NFT',
    t182: 'It is expected to get a profit today',
    t183: 'The time to obtain pledge income',
    t184: 'Cycle lock time',
    t185: ' day',
    t186: 'The proportion of computing power in the entire network',
    t187: 'The first 15 days of the pledged NFT is the lock-up time, during which the pledged NFT cannot be redeemed, and within 24 hours of the 16th day of the pledge is the redemption time, during which the pledged NFT can be redeemed, From the 17th day of the pledge, a 15-day lock-up period begins, that is, the lock-up period from the 17th day to the 32nd day is non-redeemable, and the 24-hour period on the 33rd day is the redeemable period. analogy. ',
    t188: 'Pledgeable',
    t189: 'Pledged',

    t190: 'redeemable',
    t191: 'Lock state',
    t192: 'Use fragments to synthesize True NFT',
    t193: 'Go to exchange',
    t194: 'name',
    t195: 'Description',
    t196: 'Purchase record',
    t197: 'NFT name',
    t198: 'Price(USDT)',
    t199: 'Exchange record',

    t200: 'Exchange direction',
    t201: 'Get Quantity',
    t202: 'Exchange quantity (pieces)',
    t203: 'Expense (OMT)',
    t204: 'Invitation link',
    t205: 'Invitation record',
    t206: 'Wallet address',
    t207: 'What is a global node? ',
    t208: 'Global Node is an official long-term event held by True Shop. Every user participates by default. After meeting the requirements, airdrops and computing power rewards will be issued. ',
    t209: 'Global node level',

    t210: 'Earl, Marquis, and Duke are three tiers, and each rank has a three-star rating. The higher the rank, the higher the computing power. ',
    t211: 'Global node specific requirements',
    t212: 'Pledge record',
    t213: 'True NFT number',
    t214: 'Hashrate record',
    t215: 'Total computing power',
    t216: 'own computing power',
    t217: 'Invite direct calculation power',
    t218: 'Calculation between invitations',
    t219: 'Node direct calculation power',

    t220: 'Node cross computing power',
    t221: 'True NFT number',
    t222: 'Pledge income record',
    t223: 'Quantity (OMT)',
    t224: 'Redemption record',
    t225: 'Synthetic record',
    t226: 'cost (slice)',
    t227: 'No more data~',
    t228: 'The network request does not exist',
    t229: 'income',
    t230: 'slice',
    t231: 'receiving address',
    t232: 'True token is based on the OMP chain to issue NFT. As one of the One More ecological strong application DAO governance tokens, holding True token can initiate voting, voting and pledge mining on One More DAO.',
    t233: 'tourists',
    t234: 'Not yet open',
    t235: 'Direct push pledge number',
    t236: 'Number of people who invite direct push',
    t237: 'Number of pushes between invitations',
    t238: 'Number of people who push the node directly',
    t239: 'Number of nodes pushed across',
    t240: 'Number of pushers',
    t241: 'The number of interim pledges',
    t242: 'Nodes directly infer the source of computing power',
    t243: 'Node cultivation reward source of computing power',
    t244: 'The node pushes the user directly',
    t245: 'The node directly pushes the number of pledges',
    t246: 'Node cross push user',
    t247: 'The number of pledged sheets across the node',
    t248: 'introduce',
    t249: 'total computing power',
    t250: 'Node direct calculation power',
    t251: 'Nodes cultivate computing power',
    t252: "Leader's computing power",
    t253: 'Leaders cultivate computing power',
    t254: "The source of the leader's computing power",
    t255: 'Leaders cultivate sources of computing power',
    t256: 'User address',
    t257: 'Node segment',
    t258: 'lp pledge value',
    t259: 'Get computing power',
    t260: "Leader's Hashrate & Leaders Cultivate Hashrate",
    t261: "When the node level is Duke Samsung, and the sum of the number of True NFTs pledged by the users under the umbrella is greater than or equal to 1000, the leader's computing power can be obtained, which is 3% of the sum of the True NFT pledged computing power of the users under the umbrella. Two generations of fixed level can obtain the computing power of leader training, and the computing power of leader training is 10% of the computing power of lower-level leaders. ",
    t262: 'Create your NFT digital collection',
    t263: 'image',
    t264: 'Supported image types: jpg,png,jpeg',
    t265: 'abbreviation',
    t266: 'full name',
    t267: 'description',
    t268: 'author name',
    t269: 'Please enter your name',
    t270: 'Token Standard',
    t271: 'Please select a token standard',
    t272: 'Please enter your NFT short name',
    t273: 'Please enter your NFT full name',
    t274: 'Please enter your NFT description',
    t275: 'Creator Fee',
    t276: 'Please enter the creator fee',
    t277: 'Casting',
    t278: 'Please upload a picture',
    t279: 'The creator fee range is',
    t280: 'Use USDT to synthesize True NFT',
    t281: 'Official website link',
    t282: 'Please enter an integer',
    t283: 'NFT transfer',
    t284: 'LP pledge',
    t285: 'Please enter the official website link',
    t286: 'NFT pledge incentive',
    t287: 'LP pledge incentive',
    t288: 'twitter',
    t289: 'Telegram',
    t290: 'Please enter a twitter link',
    t291: 'Please enter the Discard link',
    t292: 'Please enter the telegram link',
    t293: 'Items',
    t294: 'Owner',
    t295: 'Floor price',
    t296: 'Total volume',
    t297: 'Pledge LP to get OMT tokens',
    t298: 'Enter the pledged LP value',
    t299: 'Incentive duration',
    t300: 'The total number of cumulative releases',
    t301: 'static release',
    t302: 'Share release',
    t303: 'Node release',
    t304: 'LP share',
    t305: 'LP node',
    t306: 'LP node incentive quota',
    t307: 'Note: 9.6 million incentive OMT quota, after the quota is used up, the pledge ends',
    t308: 'Please enter the correct quantity',
    t309: 'Pledge successful',
    t310: 'Enter up to 8 decimal places',
    t311: 'Release',
    t312: 'Incentive gain',
    t313: 'Direct push',
    t314: 'Interim push',
    t315: 'node user',
    t316: 'LP node release',
    t317: 'Performance reward',
    t318: 'Equal performance reward',
    t319: 'Personal pledge amount',
    t320: 'The number of team pledges',
    t321: 'LP release record',
    t322: 'release acquired',
    t323: 'LP pledge record',
    t324: 'LP node cumulative release',
    t325: 'LP node is expected to release',
    t326: 'value',
    t327: 'Total incentive value',
    t328: 'Pledge in progress',
    t329: 'Redeemed',
    t330: 'Pledge value',
    t331: 'Pledge quantity',
    t332: "Today's actual income",
    t333: 'normal output',
    t334: 'Increase output',
    t335: 'Computing power estimated income',
    t336: 'Contribution increase',
    t337: 'Please enter the number of synthetic True NFT',
    t338: 'Cost',
    t339: 'Dividend record',
    t340: 'mine pool query',
    t341: 'True NFT pledge computing power mining pool',
    t342: 'Sync',
    t343: 'The total computing power of the whole network',
    t344: 'Cumulative output',
    t345: 'Cumulative destruction',
    t346: 'Total output per day',
    t347: 'Total mining pool balance',
    t348: 'NFT exchange',
    t349: 'True NFT exchange locked OMT',
    t350: 'Please choose NFT',
    t351: 'Select',
    t352: 'TIP: This exchange is the exchange amount of the True NFT you selected',
    t353: 'Convertible NFT',
    t354: 'OMT lock release time',
    t355: 'Select NFT exchange',
    t356: 'NFT exchange record',
    t357: 'Exchanged',
    t358: 'Release',
    t359: 'Released',
    t360: 'Release',
    t361: 'Can be redeemed today',
    t362: ' days later can be redeemed',
    t363: 'Record',
    t364: 'Synthesis Reward',
    t365: 'Invitation reward',
    t366: 'Real-time reward',
    t367: 'Equivalent reward',
    t368: 'Composite time',
    t369: 'Synthetic Quantity',
    t370: 'See more',
    t371: 'Synthetic Price',
    t372: 'Transaction Amount',
    t373: 'Lock reward',
    t374: 'Remaining release quantity',
    t375: 'Article ',
    t376: ' day release',
    t377: 'Release Quantity',
    t378: 'Release time',
    t379: 'Real-time reward record',
    t380: 'Synthetic Source',
    t381: 'Bonus time',
    t382: 'Equivalent reward record',
    t383: 'remaining release',
    t384: 'Release',
    t385: 'Release record',
    t386: 'Remaining locked positions',
    t387: 'Total lockup',
    t388: 'Album',
    t389: 'All numbers',
    t390: 'Author introduction',
    t391: 'Interpretation of works',
    t392: 'About digital collections',
    t393: 'Consignment Record',
    t394: 'Overview',
    t395: 'Transaction History',
    t396: 'Source',
    t397: 'Collection Details',
    t398: 'Network',
    t399: 'Properties',
    t400: 'Deal',
    t401: 'Transaction price',
    t402: 'From',
    t403: 'to',
    t404: 'Buy Now',
    t405: 'Please switch to the corresponding chain to buy',
    t406: 'Consignment',
    t407: 'Price',
    t408: 'Number: low to high',
    t409: 'Number: high to low',
    t410: 'Price: Low to High',
    t411: 'Price: High to Low',
    t412: 'Transaction dynamics',
    t413: 'The last 7 days',
    t414: 'Last 30 days',
    t415: 'The last 90 days',
    t416: '30-day average transaction price',
    t417: '30th floor price change',
    t418: '30-day transaction volume',
    t419: '30 day transaction volume',
    t420: 'Latest release',
    t421: 'Sort by number: high to low',
    t422: 'Sort by number: low to high',
    t423: 'Price: High to Low',
    t424: 'Price: Low to High',
    t425: 'Characteristics',
    t426: 'Please select a category',
    t427: 'No data yet',
    t428: 'Synthetic Amount',
    t429: 'Current NFT exchange price',
    t430: 'Synchronizing',
    t431: 'Leaderboard',
    t432: 'Computing power dividend',
    t433: 'Recommended Collection',
    t434: 'Latest release',
    t435: 'Trading volume increase',
    t436: '7 days',
    t437: 'Classification',
    t438: 'Hot NFT',
    t439: 'Page',
    t440: 'total',
    t441: 'Casting column',
    t442: 'Cast multi-chain NFT, there is free casting, there are yellow v masters and blue v masters to apply and cast. ',
    t443: 'Popular Events',
    t444: 'Blue V application',
    t445: 'Yellow V application',
    t446: 'Personal name',
    t447: 'Company Name',
    t448: 'Please enter your personal name',
    t449: 'Please enter the company name',
    t450: 'Contact information',
    t451: 'Please enter the contact information',
    t452: 'Introduction',
    t453: 'Company Introduction',
    t454: 'Please enter a personal introduction',
    t455: 'Please enter the company introduction',
    t456: 'Upload portfolio',
    t457: 'Upload business license',
    t458: 'Submit',
    t459: 'The name cannot be empty',
    t460: 'Contact information cannot be empty',
    t461: 'The introduction cannot be empty',
    t462: 'Portfolio cannot be empty',
    t463: 'Business license cannot be empty',
    t464: 'Participation record',
    t465: 'Under review',
    t466: 'Pay now',
    t467: 'Payment verification',
    t468: 'Payment successful',
    t469: 'The amount is inconsistent',
    t470: 'Application failed',
    t471: 'Payment amount',
    t472: 'Reason for rejection',
    t473: 'Please switch to the corresponding chain for payment',
    t474: 'Participation',
    t475: 'Pledge TrueNFT mining and lock for at least 15 days to mine OMT coins, 150 million pieces, halved every 4 years, and all mining will be completed in 20 years. The mining output will get different computing power according to the number of NFT sheets locked and pledged and the time',
    t476: 'The LP node incentive pool has a total of 9.6 million OMT quota, the quota is used up, and the LP node incentive ends. The LP node pledge incentive is carried out according to the LP value of the user pool, and the LP node pledge incentive duration and the shared LP node pledge incentive duration are locked to release the amount of OMT. ',
    t477: 'Please switch to OMP chain',
    t478: 'Total transaction amount',
    t479: 'Average price',
    t480: 'Number of collections',
    t481: 'sort',
    t482: 'Time Filter',
    t483: '7 days',
    t484: 'one month',
    t485: 'Three months',
    t486: 'half a year',
    t487: 'Total transaction amount: high to low',
    t488: 'Total transaction volume: low to high',
    t489: 'Floor price: high to low',
    t490: 'Floor price: low to high',
    t491: 'Average price: high to low',
    t492: 'Average price: low to high',
    t493: 'Owner: high to low',
    t494: 'Owner: low to high',
    t495: 'Number of collections: high to low',
    t496: 'Number of collections: low to high',
    t497: 'Tools',
    t498: 'Mint NFTs',
    t499: 'NFT Free Minting',
    t500: 'Users participate in NFT creation and casting. In addition to consuming the gas of the touch contract, there is no need to pay casting fees, that is, free casting. ',
    t501: 'Blue v talent, a special casting channel for outstanding individual creators to apply for submission. The products created by blue v masters are put on the shelves and traded, with the blue v logo. ',
    t502: 'Yellow v masters, a special casting channel for the application and submission of excellent enterprise or organization creators. The products created by Huang v masters are put on the shelves and traded, with the yellow v logo. ',
    t503: 'NFT free',
    t504: 'Whether to create a new contract',
    t505: 'Yes',
    t506: 'No',
    t507: 'Enter contract',
    t508: 'Please enter the contract address',
    t509: 'Verify contract',
    t510: 'Contract verification successful',
    t511: 'Interpretation of works',
    t512: 'Please enter an interpretation of your work',
    t513: 'category',
    t514: 'Type',
    t515: "Please enter the author's introduction",
    t516: 'Brand introduction',
    t517: 'Please enter the brand introduction',
    t518: 'About digital collections',
    t519: 'Please enter the content about the digital collection',
    t520: 'Please verify the contract address',
    t521: 'The category/type of the attribute cannot be empty',
    t522: 'Blue V Master',
    t523: 'Huang V Talent',
    t524: 'Supported image types: mp4、mp3、glb、gltf',
    t525: 'Extension file',
    t526: 'The maximum number of pledged sheets per account is ',
    t527: 'Please switch to the corresponding chain operation',
    t528: 'signature failed!',
    t529: 'Available',
    t530: 'Receive the license fee',
    t531: 'receipt record',
    t532: 'Payment fee collection record',
    t533: 'chain',
    t534: 'Amount',
    t535: 'Hot DAO',
    t536: 'TrueNFT exchange',
    t537: 'TrueNFT synthesis',
    t538: 'TrueNFT pledge',
    t539: 'OMT-LP pledge',
    t540: 'Contract NFT total amount limit',
    t541: 'No limit',
    t542: 'Limit',
    t543: 'Single casting',
    t544: 'Multiple casting',
    t545: 'Casting ',
    t546: 'NFT Introduction',
    t547: 'Please enter the NFT introduction',
    t548: 'NFT included',
    t549: '{timeType} day average transaction price',
    t550: '{timeType} sky floor price change',
    t551: '{timeType} day transaction volume',
    t552: '{timeType} day sales',
    t553: '{day}day',
    t554: 'Transaction Amount',
    t555: 'Average price',
    t556: 'Project information:',
    t557: 'Project Network',
    t558: 'Token symbol',
    t559: 'Please enter the token symbol',
    t560: 'Contract address',
    t561: 'Please enter the contract address',
    t562: 'Amount of Tokens',
    t563: 'Project Introduction',
    t564: 'Please enter a project profile',
    t565: 'Such as: {text}',
    t566: 'Official URL',
    t567: 'NFT type',
    t568: 'Please select the NFT type',
    t569: 'Token Information',
    t570: 'Please enter token information',
    t571: '(image size: 200x200px, support JPG, PNG)',
    t572: 'Please enter Email',
    t573: 'Please enter the official URL',
    t574: 'transaction hash',
    t575: 'Reminder',
    t576: 'The current network environment access rights are restricted. We cannot provide services to mainland China, the United States, Singapore and other regions. Please follow the relevant regulations of your country and region to access our services. ',
    t577: "The access network is unstable due to compliance issues in the user's country and region, which is force majeure and has nothing to do with the platform. Transaction losses caused by such reasons are not within the scope of compensation. ",
    t578: 'I know',
  }
}
