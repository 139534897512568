<template>
  <div id="app">
    <headers v-if="!$route.meta.noShow" :showTab="$route.meta.showTab" />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <footers v-if="$route.meta.showBottom" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import headers from "@/components/headers/index.vue";
import footers from "@/components/footers/index.vue";
export default {
  components: {
    headers,
    footers
  },
  data(){
    return{
      uploadTimer:null,
      chain_list:[
        // {
        //   name:'OMP',
        //   chain_id:'0x2d20',
        //   chain_type:'0',
        //   chain_logo:this.$concatImg('img81')
        // },
        {
          name:'BSC',
          chain_id:'0x38',
          chain_type:'1',
          chain_logo:this.$concatImg('img79')
        },
        {
          name:'ETH',
          chain_id:'0x1',
          chain_type:'2',
          chain_logo:this.$concatImg('img80')
        },
        {
          name:'POLYGON',
          chain_id:'0x89',
          chain_type:'3',
          chain_logo:this.$concatImg('img82')
        }
      ]
    }
  },
  created(){
    this.checkLang()
    this.getconfig()
    this.setchainlist(this.chain_list)
  },
  mounted() {
    this.checkIsMobile()
    // console.log("app", document.body.clientWidth);
    window.onresize = () => {
      this.checkIsMobile()
    };
    this.uploadTimer = setInterval(() => {
      this.setchainId(ethereum.chainId)
    }, 2000);
  },
  methods: {
    ...mapMutations({
      changeDev: "CHANGE_DEVICE",
      setlang: "SET_LANGUAGE",
      setconfig:"SET_CONFIG",
      setchainId:"SET_CHAIN_ID",
      setchainlist:"SET_CHAIN_LIST"
    }),
    checkIsMobile(){
      if (this._isMobile()) {
        this.changeDev("h5");
      } else {
        this.changeDev("pc");
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    checkLang(){
      this.$i18n.locale == 'en' ? this.setlang(2) : this.setlang(1)
    },
    getconfig(){
      this.$http.get('/index/configList').then(res=>{
        this.setconfig(res.data)
      })
    }
  },
};
</script>

<style lang="less">
.el-input__inner:focus {
  border-color: #909090 !important;
}
.el-textarea__inner:focus {
  border-color: #909090 !important;
}
.el-select-dropdown__item.selected {
  color: #606266 !important;
}
.el-loading-spinner .path{
  stroke: #606266 !important;
}
</style>
