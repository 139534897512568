const save = (key,value)=>{
  localStorage.setItem(key,value)
}

const get = (key)=> localStorage.getItem(key)

const remove = (key)=>{
  localStorage.removeItem(key)
}

export default {
  save,
  get,
  remove
}