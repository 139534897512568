<template>
  <div class="footers-style column-center">
    <div class="box1 start-center">
      <div class="box1_item center-center" v-for="item in partList" :key="item.id" @click="goLink(item.href)">
        <img :src="$http.imgurl+item.image" alt="" class="bi_img1" />
        <div class="bi_t1">{{language==1?item.title_cn:item.title}}</div>
      </div>
    </div>
    <div class="box2 center-center">
      <div class="box2_item">
        <img src="@/assets/img/img3.png" alt="" class="bi_i1" @click="goLink(config.twitter)" />
      </div>
      <div class="box2_item">
        <img src="@/assets/img/img4.png" alt="" class="bi_i1" @click="copy(config.email)" />
      </div>
    </div>
    <div class="box3">
      <div class="b3_t1 center-center" @click="showlang">
        <span>{{language==1?'中文':'English'}}</span>
        <van-icon name="arrow-down" />
      </div>
      <div class="b3_add_l1" v-if="showL">
        <div class="b3-l2">
          <div class="b3-l2-t1" @click="changeLang('zh')">中文</div>
          <div class="b3-l2-t1" @click="changeLang('en')">English</div>
        </div>
      </div>
      <div class="b3_l1"></div>
      <div class="b3_t1" @click="goAhead('/included')">{{ $t('text.t548') }}</div>
      <div class="b3_l1"></div>
      <div class="b3_t1">{{$t('text.t2')}}</div>
      <div class="b3_l1"></div>
      <!-- <div class="b3_t1">{{$t('text.t3')}}</div>
      <div class="b3_l1"></div> -->
      <div class="b3_t1">@2022 TRUE SHOP</div>
    </div>
    <img src="@/assets/img/bg1.png" alt="" class="box4">
  </div>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  data(){
    return {
      partList:[],
      showL:false
    }
  },
  computed:{
    ...mapState(["config","language"]),
  },
  created(){
    this.getPart()
  },
  methods:{
    ...mapMutations({
      setlang:'SET_LANGUAGE'
    }),
    goLink(url){
      url ? window.location.href = url : this.$toast(this.$t('text.t4'))
    },
    getPart(){
      this.$http.get('/index/partner',{
        page:1,
        limit:20,
      }).then(res=>{
        this.partList = res.data.data
      })
    },
    showlang(){
      this.showL = !this.showL
    },
    changeLang(e){
      this.$i18n.locale = e
      this.$local.save('shop:lang',e)
      this.$i18n.locale == 'en' ? this.setlang(2) : this.setlang(1)
      this.showL = false
    },
  }
};
</script>

<style lang="less">
.footers-style {
  width: 100%;
  background: #000000;
  padding: 21px 0 18px;
  .box1 {
    width: 264px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .box1_item {
      width: 33.3%;
      margin-bottom: 8px;
      .bi_img1 {
        width: 12px;
        height: auto;
      }
      .bi_t1 {
        font-size: 10px;
        font-weight: 300;
        color: #909090;
        margin-left: 4px;
      }
    }
  }
  .box2 {
    margin-bottom: 20px;
    .box2_item {
      padding: 0 15px;
      .bi_i1 {
        width: 16px;
        height: auto;
      }
    }
  }
  .box3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
    .b3_t1 {
      font-size: 12px;
      font-weight: 300;
      color: #909090;
      margin-bottom: 5px;
      span{
        margin-right: 4px;
      }
    }
    .b3_l1 {
      width: 48px;
      height: 1px;
      background: #909090;
      margin-bottom: 20px;
    }
    .b3_add_l1{
      width: 48px;
      // margin-bottom: 20px;
    }
    .b3-l2{
      border: 1px solid #2B2B2B;
      border-radius: 5px;
      .b3-l2-t1{
        text-align: center;
        padding: 6px 0;
        border-bottom: 1px solid #323232;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #909090;
      }
    }
  }
  .box4{
    width: 375px;
    height: 66px;
  }
}
@media all and (min-width: 1080px) {
  .footers-style {
    width: 100%;
    background: #000000;
    padding: 56Px 0 60Px;
    .box1 {
      width: 590Px;
      flex-wrap: wrap;
      margin-bottom: 20Px;
      .box1_item {
        width: 33.3%;
        margin-bottom: 30Px;
        .bi_img1 {
          width: 20Px;
          height: auto;
        }
        .bi_t1 {
          font-size: 20Px;
          font-weight: 300;
          color: #909090;
          margin-left: 6Px;
        }
      }
    }
    .box2 {
      margin-bottom: 50Px;
      .box2_item {
        padding: 0 40Px;
        .bi_i1 {
          width: 42Px;
          height: auto;
        }
      }
    }
    .box3 {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 55Px;
      .b3_t1 {
        font-size: 32Px;
        font-weight: 300;
        color: #909090;
        margin-bottom: 15Px;
      }
      .b3_l1 {
        width: 127Px;
        height: 1px;
        background: #909090;
        margin-bottom: 55Px;
      }
    }
    .box4{
      width: 1366Px;
      height: 204Px;
    }
  }
}
</style>