import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "lib-flexible"

import '@/assets/style/basics.css'

import request from '@/request/request.js';
Vue.prototype.$http = request

import mixin from '@/mixin/index.js'
Vue.mixin(mixin)

import local from '@/utils/local.js'
Vue.prototype.$local = local

import Web3 from 'web3'
Vue.prototype.Web3 = Web3

import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import {
  parseTime
} from "@/utils/date";
Vue.prototype.$parseTime = parseTime

import vueClipboard from 'vue-clipboard2'
Vue.use(vueClipboard)

import i18n from '@/i18n/index'

Vue.prototype.$bus = new Vue()

Vue.prototype.$concatImg = function (url) {
  return require(`@/assets/img/${url}.png`)
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
