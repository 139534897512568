<template>
  <van-sticky>
    <div class="header-style between-center">
      <div class="left start-center" v-if="showTab">
        <div class="mr20" :class="actives==1?'left-t1':'left-t1-no'" @click="setactives(1)">True shop</div>
        <div :class="actives==2?'left-t1':'left-t1-no'" @click="setactives(2)">Mints</div>
      </div>
      <div class="left start-center" v-else>
        <div class="left-t1" @click="goIndex">True shop</div>
      </div>
      <div class="right end-center">
        <!-- <img
          src="@/assets/img/img70.png"
          alt=""
          class="img2 mr20"
          @click="goAhead('/search')"
        /> -->
        <img
          src="@/assets/img/img71.png"
          alt=""
          class="img2 mr20"
          @click="menushow = true"
        />
        <el-popover
          v-if="islogin"
          placement="bottom"
          trigger="click">
          <div class="zk-rt2">{{addr2}}</div>
          <div class="zk-rt3 center-center" @click="clearInfo">
            <img class="zk-rt3-img1" src="@/assets/img/img23.png" alt="" />
            <div class="zk-rt3-t1">{{$t('text.t15')}}</div>
          </div>
          <!-- <div class="t1" slot="reference">{{addr1}}</div> -->
          <img
            src="@/assets/img/img78.png"
            alt=""
            class="img2"
            slot="reference"
          />
        </el-popover>
        <div v-else class="t1" slot="reference" @click="connectWallter">{{$t('text.t16')}}</div>
      </div>
      <van-popup position="right" v-model="menushow">
        <div class="pop-box1">
          <!-- <div class="pop-item">
            <div class="pi-box between-center" @click="goAhead('/mints')">
              <div class="start-center">
                <img src="@/assets/img/img24.png" alt="" class="pi-left1" />
                <div class="pi-left2">{{$t('text.t6')}}</div>
              </div>
              <van-icon color="#000" name="arrow" size="0.32rem" />
            </div>
          </div> -->
          <template v-if="chainId == '0x2d20'">
            <!-- <div class="pop-item">
              <div class="pi-box between-center" @click="goAhead('/synthesis')">
                <div class="start-center">
                  <img src="@/assets/img/img26.png" alt="" class="pi-left1" />
                  <div class="pi-left2">{{$t('text.t9')}}</div>
                </div>
                <van-icon color="#000" name="arrow" size="0.32rem" />
              </div>
            </div> -->
            <!-- NFT转账 -->
            <!-- <div class="pop-item">
              <div class="pi-box between-center" @click="goOutLink">
                <div class="start-center">
                  <img src="@/assets/img/img25.png" alt="" class="pi-left1" />
                  <div class="pi-left2">{{$t('text.t283')}}</div>
                </div>
                <van-icon color="#000" name="arrow" size="0.32rem" />
              </div>
            </div> -->
            <!-- <div class="pop-item">
              <div class="pi-box between-center" @click="goAhead('/exchange')">
                <div class="start-center">
                  <img src="@/assets/img/img28.png" alt="" class="pi-left1" />
                  <div class="pi-left2">{{$t('text.t348')}}</div>
                </div>
                <van-icon color="#000" name="arrow" size="0.32rem" />
              </div>
            </div> -->
            <!-- <div class="pop-item">
              <div class="pi-box between-center" @click="goAhead('/pledge')">
                <div class="start-center">
                  <img src="@/assets/img/img27.png" alt="" class="pi-left1" />
                  <div class="pi-left2">{{$t('text.t286')}}</div>
                </div>
                <van-icon color="#000" name="arrow" size="0.32rem" />
              </div>
            </div> -->
            <!-- LP质押 -->
            <!-- <div class="pop-item">
              <div class="pi-box between-center" @click="goAhead('/lpPlege')">
                <div class="start-center">
                  <img src="@/assets/img/img69.png" alt="" class="pi-left1" />
                  <div class="pi-left2">{{$t('text.t287')}}</div>
                </div>
                <van-icon color="#000" name="arrow" size="0.32rem" />
              </div>
            </div> -->
          </template>
          <!-- <div class="pop-item" v-if="chainId == '0x2d20'">
            <div class="pi-box between-center" @click="goAhead('/globalnode')">
              <div class="start-center">
                <img src="@/assets/img/img29.png" alt="" class="pi-left1" />
                <div class="pi-left2">{{$t('text.t12')}}</div>
              </div>
              <van-icon color="#000" name="arrow" size="0.32rem" />
            </div>
          </div> -->
          <div class="pop-item">
            <div class="pi-box between-center" @click="goAhead('/invite')">
              <div class="start-center">
                <img src="@/assets/img/img30.png" alt="" class="pi-left1" />
                <div class="pi-left2">{{$t('text.t13')}}</div>
              </div>
              <van-icon color="#000" name="arrow" size="0.32rem" />
            </div>
          </div>
          <div class="pop-item">
            <div class="pi-box between-center" @click="goAhead('/my')">
              <div class="start-center">
                <img src="@/assets/img/img31.png" alt="" class="pi-left1" />
                <div class="pi-left2">{{$t('text.t14')}}</div>
              </div>
              <van-icon color="#000" name="arrow" size="0.32rem" />
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <van-dialog v-model="showDialog" :title="$t('text.t575')" theme="round-button" confirm-button-color="#323232" :confirm-button-text="$t('text.t578')">
      <div style="line-height: 1.5;font-size: 12px;color: #323232;padding: 20px 5% 10px;">
        <span>
          {{ $t('text.t576') }}
        </span>
        <div style="height: 10px;"></div>
        <span>
          {{ $t('text.t577') }}
        </span>
      </div>
    </van-dialog>
  </van-sticky>
</template>

<script>
import { mapState,mapMutations } from "vuex";
export default {
  props:["showTab"],
  data() {
    return {
      showLoginout: false,
      menushow: false,
      addr1:'',
      addr2:'',
      showDialog:false
    };
  },
  computed: {
    ...mapState(["device","islogin","language","chainId","actives"]),
  },
  created(){
    this.checkAccount()
  },
  watch:{
    chainId(val,old){
      if(old){
        console.log('trueee');
        this.reloadIndex()
      }
    }
  },
  methods:{
    ...mapMutations({
      setlogin:'SET_LOGIN_STATE',
      setactives:'SET_ACTIVES'
    }),
    goOutLink(){
      window.location.href = 'http://transfer.trueshop.top/'
    },
    reloadIndex(){
      this.$router.replace("/")
      setTimeout(() => {
        location.reload()
      }, 300);
    },
    goIndex(){
      this.$router.replace("/")
    },
    handleCommand(command) {
      if(command == '/nfttransfer'){
        this.goOutLink()
      } else{
        this.goAhead(command)
      }
    },
    async connectWallter(){
      if(typeof window.ethereum !== 'undefined'){
				const accounts = await ethereum.request({method: 'eth_requestAccounts'});
				console.log(accounts);
        // this.loginRegister(accounts[0])

        const signStr = await ethereum.request({method:'personal_sign',params:["Trueshop",accounts[0]]})
				// console.log(signStr);
				const web3 = new this.Web3(ethereum)
				const signAddress = await web3.eth.accounts.recover("Trueshop",signStr)
				// console.log(signAddress.toLowerCase());
        if(accounts[0].toLowerCase() == signAddress.toLowerCase()){
          this.loginRegister(accounts[0])
        } else{
          this.$toast(this.$t('text.t528'))
        }
			}
    },
    loginRegister(addre){
      const invite = this.$local.get("shop:invite")
			const data = {
				address:addre,
				language:this.language,
				invite
			}
			this.$http.post('/index/loginRegister',data).then(res=>{
				this.$local.save('shop:account',addre)
				this.$local.save('shop:token',res.data.token)
				this.$local.save('shop:uid',res.data.id)
				this.$toast(res.msg)
				this.setlogin(true)
        console.log(this.islogin);
        this.subAddress(addre)
        this.update721_1155()
			})
		},
    async checkAccount(){
      console.log(this.$local.get('shop:account'));
      if(this.$local.get('shop:account')){
        let timer = null;
        timer = setInterval( async () => {
          if(typeof window.ethereum !== 'undefined'){
            clearInterval(timer)
            const accounts = await ethereum.request({method: 'eth_requestAccounts'});
            accounts[0] != this.$local.get('shop:account') ? this.clearInfo() : this.checkToken()
          }
        }, 200);
      } else{
        this.setlogin(false)
      }

      const dialogTime = localStorage.getItem('shop:dialogtime')
      let t = Date.now();
      let newT = (t + 86400000).toString();
      if(dialogTime && dialogTime != 'undefined'){
        if(t >= Number(dialogTime)){
          //弹窗
          this.showDialog = true;
          //重置时间
          localStorage.setItem('shop:dialogtime',newT)
        }
      } else{
        //弹窗
        this.showDialog = true;
        //设置时间
        localStorage.setItem('shop:dialogtime',newT)
      }
    },
    clearInfo(){
			this.$local.remove('shop:account')
			this.$local.remove('shop:token')
			this.$local.remove('shop:uid')
			this.setlogin(false)
		},
		checkToken(){
			this.$http.post('/token/check').then(res=>{
				this.setlogin(true)
        const addrs = this.$local.get('shop:account')
        // console.log(window.ethereum['chainId']);
        this.subAddress(addrs)
        this.update721_1155()
			}).catch(err=>{
				if(err.code == 401){
					this.clearInfo()
				}
			})
		},
    subAddress(addr){
      this.addr1 = addr.substr(0,5) + '...' + addr.substr(addr.length-3)
      this.addr2 = addr.substr(0,12) + '...' + addr.substr(addr.length-10)
    },
    update721_1155(){
      this.$http.post('/collections/userAsset721')
      this.$http.post('/collections/userAsset1155')
      this.$http.post('/order/pendingOrder')
    }
  }
};
</script>

<style lang="less">
.header-style {
  background-color: #fff;
  width: 100%;
  height: 44px;
  padding: 0 12px 0 20px;
  box-sizing: border-box;
  box-shadow: 0 6px 6px rgba(50, 50, 50, 0.1);
  .left {
    .img1 {
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
    .left-t1{
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    .left-t1-no{
      font-size: 18px;
      font-weight: bold;
      color: #909090;
    }
  }
  .right {
    .t1 {
      padding: 10px 13px;
      background: #323232;
      border-radius: 20px;
      font-size: 12px;
      font-weight: bold;
      color: #ffffff;
      position: relative;
      .t1-pos {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 999;
        .t1-pos-pos {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 6px;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0 0 6px rgba(50, 50, 50, 0.3);
          .pp-t1 {
            padding: 26px 10px 15px;
            font-size: 12px;
            font-weight: bold;
            color: #323232;
            border-bottom: 1px solid #e1e1e1;
          }
          .pp-t2 {
            padding: 15px 20px;
            .pp-t2-i1 {
              width: 13px;
              height: 12px;
              margin-right: 8px;
            }
            .pp-t2-t1 {
              font-size: 12px;
              font-weight: bold;
              color: #323232;
            }
          }
        }
      }
    }
    .img2 {
      width: 20px;
      height: 20px;
    }
    
  }
  .mr20{
    margin-right: 20px;
  }
  .pop-box1 {
    width: 290px;
    height: 100vh;
    background-color: #fff;
    .pop-item {
      padding: 25px;
      .pi-box {
        .pi-left1 {
          width: 21px;
          height: auto;
          margin-right: 15px;
        }
        .pi-left2 {
          font-size: 15px;
          font-weight: bold;
          color: #000000;
        }
      }
    }
  }
}
.el-dropdown-menu__item:hover{
  background-color: #f1f1f1 !important;
  color: #000000 !important;
}
.zk-rt2{
  font-size: 12px;
  font-weight: bold;
  color: #323232;
  padding: 15px 0;
  text-align: center;
  border-bottom: 1px solid #E1E1E1;
}
.zk-rt3{
  padding-top: 15px;
  .zk-rt3-img1{
    width: 13px;
    height: 12px;
    margin-right: 8px;
  }
  .zk-rt3-t1{
    font-size: 12px;
    font-weight: bold;
    color: #323232;
  }
}
.el-popover{
  border-radius: 15px !important;
}
</style>