export default {
  text: {
    t1: '隐私条款',
    t2: '联系我们',
    t3: '服务协议',
    t4: '跳转链接不存在',
    t5: '活动',
    t6: '铸造',
    t7: '转入',
    t8: '兑换',
    t9: '合成',
    t10: '质押',
    t11: '跨链兑换',
    t12: '全球节点',
    t13: '邀请',
    t14: '我的',
    t15: '登出',
    t16: '连接钱包',
    t17: '复制成功',
    t18: '复制失败',
    t19: '公共区',
    t20: '创建合集',
    t21: '精选最热NFT',
    t22: 'NFT广场',
    t23: '获得收藏最多的合集',
    t24: '成交量',
    t25: '持有人',
    t26: '收藏排行',
    t27: '热度最高的合集',
    t28: '关于True Shop',
    t29: '完整用户指南',
    t30: '常见问题',
    t31: '默认昵称',
    t32: '简介',
    t33: '暂未填写简介',
    t34: '粉丝',
    t35: '关注',
    t36: '上架',
    t37: '我的NFT',
    t38: '搜索你想找的NFT合约地址',
    t39: '没有更多了',

    t40: '我的合集',
    t41: '搜索你想找的合集名称',
    t42: '喜欢的NFT',
    t43: '搜索你想找的NFT名称',
    t44: '价格',
    t45: '收藏的合集',
    t46: '我铸造的',
    t47: '我上架的',
    t48: '我关注的',
    t49: '搜索你想找的用户名称',

    t50: '暂无昵称',
    t51: '更改合集',
    t52: '取消',
    t53: '合集',
    t54: '请选择合集',
    t55: '移动',
    t56: '个人资料详情',
    t57: '个人资料图片',
    t58: '个人资料横幅',
    t59: '用户名',

    t60: '输入你的用户名',
    t61: '简介',
    t62: '请输入对您的简介',
    t63: '电子邮件地址',
    t64: '输入你的电子邮件',
    t65: '钱包地址',
    t66: '一键复制',
    t67: '提交中...',
    t68: '确定',
    t69: '请上传个人资料图片',

    t70: '请上传个人资料横幅',
    t71: '请输入你的用户名',
    t72: '请输入对您的简介',
    t73: '请输入你的电子邮件',
    t74: '上传中...',
    t75: '上传成功',
    t76: '上传失败',
    t77: '查看详情',
    t78: '已收藏',
    t79: '收藏合集',

    t80: '合集描述',
    t81: '搜索心仪的NFT',
    t82: '编辑合集',
    t83: '创建合集',
    t84: 'logo图片',
    t85: '此图片推荐',
    t86: '精选图片',
    t87: '尺寸',
    t88: '背景图片',
    t89: '合集名称',

    t90: '请输入合集名称',
    t91: '合集介绍',
    t92: '请输入合集介绍',
    t93: '创建',
    t94: '删除合集',
    t95: '请上传logo图片',
    t96: '请上传精选图片',
    t97: '请上传背景图片',
    t98: '请输入合集名称',
    t99: '请输入合集介绍',

    t100: '下架',
    t101: '立刻购买',
    t102: '信息',
    t103: '数量',
    t104: '合约地址',
    t105: '令牌ID',
    t106: '代币标准',
    t107: '区块链',
    t108: '创作者名称',
    t109: '创作者费用',

    t110: '质押说明',
    t111: '价格历史',
    t112: '请选择日期',
    t113: '项目活动',
    t114: '途径',
    t115: '价格',
    t116: '持有人',
    t117: '获得者',
    t118: '时间',
    t119: '下架选择',

    t120: '购买选择',
    t121: '选择您下架的NFT的数量',
    t122: '选择您购买的NFT的数量',
    t123: '购买',
    t124: '共计',
    t125: '张可',
    t126: '收藏排行',
    t127: '数量',
    t128: '请输入数量',
    t129: '请输入价格',

    t130: '交易手续费(不包含上架)',
    t131: '跨链兑换',
    t132: 'USDT跨链兑换',
    t133: '库存',
    t134: '兑换比例为',
    t135: '兑换',
    t136: '兑换池',
    t137: 'OMT代币兑换NFT碎片',
    t138: '碎片',
    t139: '去合成',

    t140: '获取比例中...',
    t141: '段位',
    t142: '普通用户',
    t143: '伯爵一星',
    t144: '伯爵二星',
    t145: '伯爵三星',
    t146: '侯爵一星',
    t147: '侯爵二星',
    t148: '侯爵三星',
    t149: '公爵一星',
    t150: '公爵二星',
    t151: '公爵三星',
    t152: '还需个人质押',
    t153: '张',
    t154: '直推质押',
    t155: '间推质押',
    t156: '即可升级',
    t157: '节点算力',
    t158: '我的节点码',
    t159: '一键复制',
    t160: '输入节点码',
    t161: '请输入节点码',
    t162: '关于全球节点',
    t163: '转入你的NFT数字藏品',
    t164: '请选择代币标准',
    t165: '请输入您的合约地址',
    t166: '查询',
    t167: '选择合集',
    t168: '请选择合集',
    t169: '添加',

    t170: '未查询到nft',
    t171: 'True NFT质押挖矿收益',
    t172: 'True NFT进行质押与赎回',
    t173: '全部质押',
    t174: '批量质押',
    t175: '全部赎回',
    t176: '赎回',
    t177: '批量赎回',
    t178: '累计质押收益',
    t179: '已质押NFT',

    t180: '可质押NFT',
    t181: '可赎回NFT',
    t182: '预计今日获得收益',
    t183: '质押收益获得时间',
    t184: '循环锁定时间',
    t185: '天',
    t186: '全网算力占比',
    t187: '在质押NFT的前15天内为锁定时间，该时间内不可赎回质押的NFT，在质押的第16天的24小时内，为赎回时间，该时间内可以赎回质押的NFT,在质押的第17天起，开始为期15天的锁定时间，也就是在第17天到第32天内为锁定时间，不可赎回，第33天的24小时内则为可赎回时间，以此类推。',
    t188: '可质押',
    t189: '已质押',

    t190: '可赎回',
    t191: '锁定状态',
    t192: '使用碎片合成True NFT',
    t193: '去兑换',
    t194: '名称',
    t195: '说明',
    t196: '购买记录',
    t197: 'NFT名称',
    t198: '价钱(USDT)',
    t199: '兑换记录',

    t200: '兑换方向',
    t201: '获得数量',
    t202: '兑换数量（片）',
    t203: '花费（OMT）',
    t204: '邀请链接',
    t205: '邀请记录',
    t206: '钱包地址',
    t207: '什么是全球节点？',
    t208: '全球节点是True Shop官方举行的长期活动，每一位用户默认参加，达到要求后将发放空投和算力奖励。',
    t209: '全球节点等级',

    t210: '伯爵、侯爵、公爵三大段位，每一段位分三星等级，段位星级越高，获取算力就越高。',
    t211: '全球节点具体要求',
    t212: '质押记录',
    t213: 'True NFT编号',
    t214: '算力记录',
    t215: '总算力值',
    t216: '自有算力',
    t217: '邀请直推算力',
    t218: '邀请间推算力',
    t219: '节点直推算力',

    t220: '节点跨推算力',
    t221: 'True NFT编号',
    t222: '质押收益记录',
    t223: '数量(OMT)',
    t224: '赎回记录',
    t225: '合成记录',
    t226: '花费（片）',
    t227: '没有更多数据了~',
    t228: '网络请求不存在',
    t229: '收益',
    t230: '片',
    t231: '接收地址',
    t232: 'True令牌是基于OMP链上发行NFT，作为One More生态强应用DAO治理通证之一,持有True令牌可对One More DAO 发起投票、表决以及质押挖矿。',
    t233: '游客',
    t234: '暂未开放',
    t235: '直推质押张数',
    t236: '邀请直推人数',
    t237: '邀请间推人数',
    t238: '节点直推人数',
    t239: '节点跨推人数',
    t240: '间推人数',
    t241: '间推质押张数',
    t242: '节点直推算力来源',
    t243: '节点培育奖励算力来源',
    t244: '节点直推用户',
    t245: '节点直推质押张数',
    t246: '节点跨推用户',
    t247: '节点跨推质押张数',
    t248: '介绍',
    t249: '总算力',
    t250: '节点直推算力',
    t251: '节点培育算力',
    t252: '领袖算力',
    t253: '领袖培育算力',
    t254: '领袖算力来源',
    t255: '领袖培育算力来源',
    t256: '用户地址',
    t257: '节点段位',
    t258: 'lp质押值',
    t259: '获得算力',
    t260: '领袖算力&领袖培育算力',
    t261: '节点等级为公爵三星时，且伞下用户质押True NFT张数之和大于等于1000张时可获得领袖算力，为伞下用户True NFT质押算力之和的3%。两代固定平级可获得领袖培育算力，领袖培育算力为下级领袖算力的10%。',
    t262: '创建你的NFT数字藏品',
    t263: '图像',
    t264: '支持的图像类型：jpg、png、jpeg',
    t265: '简称',
    t266: '全称',
    t267: '描述',
    t268: '作者名称',
    t269: '请输入您的名称',
    t270: '代币标准',
    t271: '请选择代币标准',
    t272: '请输入您的NFT简称',
    t273: '请输入您的NFT全称',
    t274: '请输入对您的NFT说明',
    t275: '创作者费用',
    t276: '请输入创作者费用',
    t277: '铸造',
    t278: '请上传图片',
    t279: '创作者费用范围为',
    t280: '使用USDT合成True NFT',
    t281: '官网链接',
    t282: '请输入整数',
    t283: 'NFT转账',
    t284: 'LP质押',
    t285: '请输入官网链接',
    t286: 'NFT质押激励',
    t287: 'LP质押激励',
    t288: '推特',
    t289: '电报',
    t290: '请输入推特链接',
    t291: '请输入Discard链接',
    t292: '请输入电报链接',
    t293: '藏品',
    t294: '拥有者',
    t295: '地板价',
    t296: '总交易额',
    t297: '质押LP以获得OMT代币',
    t298: '输入质押LP值',
    t299: '激励时长',
    t300: '累计释放总数',
    t301: '静态释放',
    t302: '分享释放',
    t303: '节点释放',
    t304: 'LP分享',
    t305: 'LP节点',
    t306: 'LP节点激励额度',
    t307: '注：960万激励OMT额度，额度用完后，质押结束',
    t308: '请输入正确的数量',
    t309: '质押成功',
    t310: '最多输入小数点后8位',
    t311: '释放',
    t312: '激励获得',
    t313: '直推',
    t314: '间推',
    t315: '节点用户',
    t316: 'LP节点释放',
    t317: '业绩奖励',
    t318: '平级业绩奖励',
    t319: '个人质押数量',
    t320: '团队质押数量',
    t321: 'LP释放记录',
    t322: '释放获得',
    t323: 'LP质押记录',
    t324: 'LP节点累计释放',
    t325: 'LP节点预计释放',
    t326: '价值',
    t327: '激励总值',
    t328: '质押中',
    t329: '已赎回',

    t330: '质押值',
    t331: '质押数量',
    t332: '今日实际收益',
    t333: '正常产出',
    t334: '增幅产出',
    t335: '算力预估收益',
    t336: '贡献增幅',
    t337: '请输入合成True NFT的数量',
    t338: '花费',
    t339: '分红记录',
    t340: '矿池查询',
    t341: 'True NFT质押算力矿池',
    t342: '同步',
    t343: '全网总算力',
    t344: '累计产出',
    t345: '累计销毁',
    t346: '单日总产出',
    t347: '总矿池剩余量',
    t348: 'NFT兑换',
    t349: 'True NFT兑换锁仓OMT',
    t350: '请选择NFT',
    t351: '选择',
    t352: 'TIP：本次兑换为您选择的True NFT的兑换量',
    t353: '可兑换NFT',
    t354: 'OMT锁仓释放时间',
    t355: '选择NFT兑换',
    t356: 'NFT兑换记录',
    t357: '已兑换',
    t358: '释放中',
    t359: '已释放',
    t360: '释放',
    t361: '今天可赎回',
    t362: '天后可赎回',
    t363: '记录',
    t364: '合成奖励',
    t365: '邀请奖励',
    t366: '实时奖励',
    t367: '等值奖励',
    t368: '合成时间',
    t369: '合成数量',
    t370: '查看更多',
    t371: '合成价格',
    t372: '成交金额',
    t373: '锁仓奖励',
    t374: '剩余释放数量',
    t375: '第',
    t376: '天释放',
    t377: '释放数量',
    t378: '释放时间',
    t379: '实时奖励记录',
    t380: '合成来源',
    t381: '奖励时间',
    t382: '等值奖励记录',
    t383: '剩余释放',
    t384: '释放',
    t385: '释放记录',
    t386: '剩余锁仓',
    t387: '总锁仓',
    t388: '所属专辑',
    t389: '全部编号',
    t390: '作者介绍',
    t391: '作品解读',
    t392: '关于数字藏品',
    t393: '寄售记录',
    t394: '概览',
    t395: '交易历史',
    t396: '来源',
    t397: '藏品详情',
    t398: '网络',
    t399: '属性',
    t400: '成交',
    t401: '成交价',
    t402: '自',
    t403: '至',
    t404: '立即购买',
    t405: '请切换到对应链购买',
    t406: '寄售',
    t407: '售价',
    t408: '编号：低到高',
    t409: '编号：高到低',
    t410: '价格：低到高',
    t411: '价格：高到低',
    t412: '交易动态',
    t413: '最近7日',
    t414: '最近30日',
    t415: '最近90日',
    t416: '30日成交均价',
    t417: '30日地板价变动',
    t418: '30日交易额',
    t419: '30日成交数量',
    t420: '最新上架',
    t421: '按编号排序:高到低',
    t422: '按编号排序:低到高',
    t423: '价格:高到低',
    t424: '价格:低到高',
    t425: '特性',
    t426: '请选择分类',
    t427: '暂无数据',
    t428: '合成金额',
    t429: '当前NFT兑换价格',
    t430: '正在同步中',
    t431: '排行榜',
    t432: '算力分红',
    t433: '推荐合集',
    t434: '最新上架',
    t435: '交易量涨幅',
    t436: '7天',
    t437: '分类',
    t438: '热门NFT',
    t439: '页',
    t440: '共',
    t441: '铸造栏目',
    t442: '铸造多链的NFT，有免费铸造，有黄v达人和蓝v达人申请和铸造。',
    t443: '热门活动',
    t444: '蓝V申请',
    t445: '黄V申请',
    t446: '个人名称',
    t447: '公司名称',
    t448: '请输入个人名称',
    t449: '请输入公司名称',
    t450: '联系方式',
    t451: '请输入联系方式',
    t452: '个人介绍',
    t453: '公司介绍',
    t454: '请输入个人介绍',
    t455: '请输入公司介绍',
    t456: '上传作品集',
    t457: '上传营业执照',
    t458: '提交',
    t459: '名称不能为空',
    t460: '联系方式不能为空',
    t461: '介绍不能为空',
    t462: '作品集不能为空',
    t463: '营业执照不能为空',
    t464: '参与记录',
    t465: '审核中',
    t466: '立即支付',
    t467: '支付核验中',
    t468: '支付成功',
    t469: '金额不一致',
    t470: '申请失败',
    t471: '支付金额',
    t472: '拒绝理由',
    t473: '请切换到对应链进行支付',
    t474: '参与',
    t475: '质押TrueNFT挖矿并锁定至少15天可挖OMT币，1.5亿枚，每4年减半，20年全部挖完，挖矿产出根据锁仓质押NFT张数和时间获得不同算力',
    t476: 'LP节点激励池一共有960万OMT额度，额度用完，LP节点激励结束。按用户池子LP值进行LP节点质押激励，LP节点质押激励时长和分享LP节点质押激励时长锁仓释放OMT数量。',
    t477: '请切换到OMP链',
    t478: '总交易额',
    t479: '均价',
    t480: '藏品数量',
    t481: '排序',
    t482: '时间筛选',
    t483: '7天',
    t484: '一个月',
    t485: '三个月',
    t486: '半年',
    t487: '总交易额：高到低',
    t488: '总交易额：低到高',
    t489: '地板价：高到低',
    t490: '地板价：低到高',
    t491: '均价：高到低',
    t492: '均价：低到高',
    t493: '拥有者：高到低',
    t494: '拥有者：低到高',
    t495: '藏品数量：高到低',
    t496: '藏品数量：低到高',
    t497: '工具',
    t498: 'Mint NFTs',
    t499: 'NFT免费铸造',
    t500: '用户参与NFT创作铸造，除了消耗触碰合约的gas外，不需要支付铸造费，即免费铸造。',
    t501: '蓝v达人，针对优秀个人创造者申请提交的特殊铸造通道。蓝v达人创作出的产品上架交易，有蓝v标识。',
    t502: '黄v达人，针对优秀企业或组织创造者申请提交的特殊铸造通道。黄v达人创作出的产品上架交易，有黄v标识。',
    t503: 'NFT免费',
    t504: '是否创立新合约',
    t505: '是',
    t506: '否',
    t507: '输入合约',
    t508: '请输入合约地址',
    t509: '验证合约',
    t510: '合约验证成功',
    t511: '作品解读',
    t512: '请输入对您的作品解读',
    t513: '类目',
    t514: '类型',
    t515: '请输入作者介绍',
    t516: '品牌介绍',
    t517: '请输入品牌介绍',
    t518: '关于数字藏品',
    t519: '请输入关于数字藏品内容',
    t520: '请验证合约地址',
    t521: '属性的类目/类型不能为空',
    t522: '蓝V达人',
    t523: '黄V达人',
    t524: '支持的图像类型：mp4、mp3、glb、gltf',
    t525: '扩展文件',
    t526: '每个账号最多质押张数为',
    t527: '请切换到对应链操作',
    t528: '签名失败!',
    t529: '可领取',
    t530: '领取版费',
    t531: '领取记录',
    t532: '版费领取记录',
    t533: '链',
    t534: '金额',
    t535: '热门DAO',
    t536: 'TrueNFT兑换',
    t537: 'TrueNFT合成',
    t538: 'TrueNFT质押',
    t539: 'OMT-LP质押',
    t540: '合约NFT总量限制',
    t541: '不限制',
    t542: '限制',
    t543: '单个铸造',
    t544: '多个铸造',
    t545: '铸造',
    t546: 'NFT介绍',
    t547: '请输入NFT介绍',
    t548: 'NFT收录',
    t549: '{timeType}天成交均价',
    t550: '{timeType}天地板价变动',
    t551: '{timeType}天交易额',
    t552: '{timeType}天销量',
    t553: '{day}天',
    t554: '交易额',
    t555: '平均价格',
    t556: '项目信息：',
    t557: '项目网络',
    t558: '代币符号',
    t559: '请输入代币符号',
    t560: '合约地址',
    t561: '请输入合约地址',
    t562: '代币数量',
    t563: '项目简介',
    t564: '请输入项目简介',
    t565: '如：{text}',
    t566: '官方网址',
    t567: 'NFT类型',
    t568: '请选择NFT类型',
    t569: '代币信息',
    t570: '请输入代币信息',
    t571: '(图片大小：200x200px，支持JPG、PNG)',
    t572: '请输入Email',
    t573: '请输入官方网址',
    t574: '交易HASH',
    t575: '温馨提示',
    t576: '当前的网络环境访问权限受到限制，我们无法为中国大陆、美国、新加坡等地区提供服务，请您遵守所在国家和地区相关规定访问我们的服务。',
    t577: '因用户所在国家和地区合规问题而导致访问网络不稳定，属于不可抗力，与平台无关，此类原因导致的交易损失不在赔付范围。',
    t578: '我知道了',
    t579: '',
    t580: '',
    t581: '',
    t582: '',
    t583: '',
    t584: '',
    t585: '',
    t586: '',
    t587: '',
    t588: '',
    t589: '',
  }
}
