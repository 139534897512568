import Vue from 'vue'
import Vuex from 'vuex'
import request from '@/request/request.js';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    device:'h5',
    islogin:false,
    language:1,
    config:{},
    user:{},
    collectionList:[],
    chainId:'',
    chain_list:[],
    actives:1
  },
  mutations: {
    CHANGE_DEVICE(state,payload){
      state.device = payload
      console.log(state.device);
    },
    SET_LOGIN_STATE(state,payload){
      state.islogin = payload
    },
    SET_LANGUAGE(state,payload){
      state.language = payload
    },
    SET_CONFIG(state,payload){
      state.config = payload
    },
    SET_USER(state,payload){
      state.user = payload
    },
    SET_COLLECTION(state,payload){
      state.collectionList = payload
    },
    SET_CHAIN_ID(state,payload){
      state.chainId = payload
    },
    SET_CHAIN_LIST(state,payload){
      state.chain_list = payload
    },
    SET_ACTIVES(state,payload){
      state.actives = payload
    }
  },
  actions: {
    async getUser({state,commit}){
      if(state.chainId == '0x2d20'){
        await request.post('/destroy/verificationBalance')
        request.get('/index/refresh').then(res=>{
          commit('SET_USER',res.data)
        })
      } else{
        request.get('/index/refresh').then(res=>{
          commit('SET_USER',res.data)
        })
      }
    },
    getMyCollection({state,commit},payload = 1){
      request.get('/collection/collectionList',{
        collectionname:'',
        page:1,
        limit:200
      }).then(res=>{
        const publicitem = {id:0,collectionname:state.language==1?'公共区':'public area'}
        const createitem = {id:-1,collectionname:state.language==1?'创建合集':'Create a collection'}
        let arr;
        if(payload == 0){
          arr = [publicitem,...res.data.data,createitem]
        } else{
          arr = [...res.data.data,createitem]
        }
        commit('SET_COLLECTION',arr)
      })
    }
  },
  modules: {
  }
})
