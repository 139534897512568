import Vue from 'vue'
import Vuei18n from 'vue-i18n'
Vue.use(Vuei18n )
import zh from './zh.js'
import en from './en.js'

const i18n = new Vuei18n({
  locale : localStorage.getItem('shop:lang') || 'en', // 语言
  messages: {
  zh,
  en
  }
})
export default i18n