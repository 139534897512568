export default {
  methods: {
    goAhead(url) {
      if (this.menushow) this.menushow = false
      const blacklist = ['/lpPlege']
      // const blacklist = []
      if(blacklist.includes(url)){
        const user_address = this.$local.get('shop:account');
        this.$http.post('/omt_pledge/accessAuth',{address:user_address}).then(res=>{
          this.$router.push(url)
        })
        // this.$toast(this.$t('text.t234'))
        // return
      } else{
        this.$router.push(url)
      }
    },
    copy(value) {
      this.$copyText(value).then(
        (e)=>{
          this.$toast(this.$t('text.t17'))
        },
        (e)=>{
          this.$toast(this.$t('text.t18'))
        }
      );
    },
    notopen(){
      this.$toast(this.$t('text.t234'))
    }
  }
}
