/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';
import qs from 'qs';
import {
  Toast
} from 'vant';
import vuex from '../store/index'
const imgurl = 'https://trueshops.oss-cn-hongkong.aliyuncs.com';
axios.defaults.baseURL = 'https://www.trueshop.top/api'

// 请求超时时间
axios.defaults.timeout = 40000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = localStorage.getItem('shop:token');
    token && (config.headers.token = token);
    return config;
  },
  error => {
    return Promise.error(error);
  })

// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200 && response.data.code === 1) {
      return Promise.resolve(response.data);
    } else {
      Toast({
        message: response.data.msg,
        duration: 1500,
        forbidClick: true
      });
      return Promise.reject(response.data);
    }
  },
  // 服务器状态码不是200的情况    
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录                
        // 未登录则跳转登录页面，并携带当前页面的路径                
        // 在登录成功后返回当前页面，这一步需要在登录页操作。                
        case 401:
          Toast({
            message: error.response.data.msg,
            duration: 1500,
            forbidClick: true
          });
          localStorage.removeItem('shop:token')
          vuex.commit('SET_LOGIN_STATE',false)
          break;
          // 404请求不存在                
        case 404:
          Toast({
            message: this.$t('text.t228'),
            duration: 1500,
            forbidClick: true
          });
          break;
          // 其他错误，直接抛出错误提示                
        default:
          // Toast({
          //   message: error.response.data.msg,
          //   duration: 1500,
          //   forbidClick: true
          // });
      }
      return Promise.reject(error.response.data);
    }
  }
);

function getchain(){
  return new Promise((resolve,reject)=>{
    let ch = window.ethereum?.chainId
    if(ch){
      resolve(ch)
    } else{
      let timer = setInterval(() => {
        if(window.ethereum?.chainId){
          clearInterval(timer)
          resolve(ethereum.chainId)
        }
      }, 200);
    }
  })
}

async function apiRequest(method,url,params={},isupload=false){
  let chainId = await getchain()
  let arg = {
    url:url,
    method:method,
    headers:{}
  }
  // method=='GET'?arg.params = params : method=='POST'? arg.data = qs.stringify(params) :''
  method=='GET'?arg.params = Object.assign(params,{chain:chainId}) : method=='POST'? arg.data = qs.stringify(Object.assign(params,{chain:chainId})) :''
  if(isupload){
    arg.headers['Content-Type'] = 'multipart/form-data'
    arg.data = params
  }
  return axios(arg)
}

export default {
	'get': (url, params) => apiRequest('GET', url, params),
	'post': (url, params) => apiRequest('POST', url, params),
	'upload': (url, params) => apiRequest('POST', url, params,true),
	'imgurl':imgurl
}